<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <b-form
            @submit.prevent="handleSubmit(showDetails)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body>
                
                <b-row>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules=""
                    >

                      <b-form-group
                        label=" نوع العملية"
                        label-for="payment-method"
                        :state="errors.length > 0 ? false : null"
                      >
                       <h4> طلب ارتجاع</h4>
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>

                  </b-col>
                  <b-col
                    cols="3"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules=""
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                      >
                          <label>اختر مستودع </label>
                        <v-select
                          v-model="Form.main_warehouse_id"
                          :options="warehouseOption"

                          label="name"
                          :clearable="false"
                          :reduce="(val) => val.id"
                         
                        />
                      </b-form-group>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="">
                      <b-form-group
                        label="القسم"
                        label-for="user-department"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="department"
                          rules="required"
                        >
                          <v-select
                            v-model="Form.department_id"
                            :options="departmentOptions"
                            label="name"
                            :reduce="(val) => val.id"
                            input-id="user-role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                </b-row>
                <b-row>
                  <b-col col="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="me-20"
                      variant="outline-primary"
                      type="submit"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-50 mb-10"
                      />
                      <span class="align-middle"> طلب عرض المواد</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->

              <!-- Items Section -->

              <!-- Note -->
            </b-card>
          </b-form>
        </validation-observer>
        <b-card>
          <form-wizard
            ref="formWiz"
            color="#7367F0"
            :title="null"
            :subtitle="null"
            shape="square"
            :hide-buttons="true"
            class="mb-3"
          >
            <tab-content
              title="  المواد المتوفرة"

              icon="feather icon-file-text"
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >

                <b-form
                  @submit.prevent="handleSubmit(Submit)"
                >
                  <b-card>
                    <label v-if="allItem.length==0">
                      الرجاء طلب عرض المواد المتوفرة حسب المستودع والقسم
                    </label>

                    <b-card-body>

                   
                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
          <b-row v-if="thereItem" class="mb-5">
                             <b-col
                              cols="4"
                              lg="4"
                            >
  <b-form-input v-model="searchQuery" placeholder="يحث من خلال اسم المادة" />
                             </b-col>
</b-row>
                          <!-- Form Input Fields OR content inside bordered area  -->
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div class=" rounded">
                            <b-row
                              v-for="(item, index) in allItem"
                              :key="index"
                              ref="row"
                              class="pb-2"
                            >
                              <!-- Item Form -->
                              <b-col
                                cols="1"
                                lg="1"
                              >
                                <b-form-checkbox
                                  v-model="item.transaction"

                                  @change="getItem(item)"
                                />
                              </b-col>
                              <!-- ? This will be in loop => So consider below markup for single item -->
                              <b-col cols="10">
                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                <div class="d-none d-lg-flex">
                                  <b-row class="flex-grow-1 px-1">
                                    <!-- Single Item Form Headers -->
                                    
                                  </b-row>
                                 
                                </div>

                                <!-- Form Input Fields OR content inside bordered area  -->
                                <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                                <div class="d-flex border rounded">
                                  <b-row class="flex-grow-1 p-2">
                                    <!-- Single Item Form Headers -->
                                   
                                    <b-col
                                      cols="12"
                                      lg="2"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <label>المادة</label>
                                          <v-select
                                            v-model="item.item"
                                            :dir="
                                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                            "
                                            disabled
                                            :options="ItemData"
                                            label="name"

                                            :clearable="false"
                                            :reduce="(val) => val.id"
                                            class="mb-2 item-selector-title"
                                            placeholder="المادة "
                                          />
                                        </b-form-group>
                                        <b-form-invalid-feedback
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                      <b-col
                            md="3"
                            xl="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="employe"
                                :state="errors.length > 0 ? false : null"
                              >

                                <label> المورد</label>
                                <v-select
                                  v-model="item.vendor_id"
                                  label="name"
                                  :options="optionVendor"
                                  disabled

                                  :reduce="(val) => val.id"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                             <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                           
                              
                               

                                 <b-form-group
                        label="القسم"
                        label-for="user-department"
                      >
                      {{Form.department_id}}
                      </b-form-group>
                              
                            
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="3"
                            xl="4"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="end_date"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label> تاريخ الصلاحية </label>
                                <flat-pickr
                                  v-model="item.end_date"
                                  class="form-control"
                                  placeholder="حدد تاريخ "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          
                               <b-col
                    cols="4"
                    md="4"
                    xl="3"
                  
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" رقم أمر الشراء"
                        label-for="payment-method"
                      />
                      <b-form-input
                      disabled

                        v-model="item.po_number"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>

                  </b-col>
                    <b-col
                    cols="4"
                    md="4"
                    xl="3"
                    
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" رقم الفاتورة "
                        label-for="payment-method"
                      />
                      <b-form-input
                      disabled

                        v-model="item.pill_number"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                    </b-col>
                                    <b-col
                                      cols="12"
                                      lg="3"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <label>الكمية</label>
                                           {{ item.remaining_quantity }}
                                          <b-form-input
                                            v-model="item.quantity"
                                            type="number"
                                            class="mb-2"
                                            placeholder="الكمية "
                                          />
                                        </b-form-group>
                                        <b-form-invalid-feedback
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      cols="12"
                                      lg="2"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                      >
                                        <b-form-group
                                          label-for="notes"
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <label>الواحدة</label>
                                          <v-select
                                            v-model="item.unit"
                                            :dir="
                                              $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                            "
                                            disabled
                                            :options="ubitsOption"
                                            label="en_name"
                                            :clearable="false"
                                            :reduce="(val) => val.id"
                                            class="mb-2 item-selector-title"
                                            placeholder="الواحدة "
                                          />

                                        </b-form-group>
                                        <b-form-invalid-feedback
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>

                                    <b-col
                                      cols="12"
                                      lg="2"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules=""
                                      >
                                        <b-form-group
                                          label-for="notes"
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <label> الحالة</label>

                                          <v-select
                                            v-model="item.status"
                                            disabled

                                            placeholder="الحالة"
                                            :options="condition"
                                            :clearable="false"
                                            :reduce="(val) => val.value"
                                            class="-2 item-selector-title"
                                          />

                                        </b-form-group>
                                        <b-form-invalid-feedback
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      cols="12"
                                      lg="3"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules=""
                                      >
                                        <b-form-group
                                          label="   "
                                          label-for="user-department"
                                        >
                                              <label> تاريخ الاستلام </label>

                                   {{ item.recive_date }}
                                        </b-form-group>
                                        <b-form-invalid-feedback
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      md="3"
                                      xl="4"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        rules=""
                                      >
                                        <b-form-group
                                          label-for="out_date"
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <label> تاريخ  اخراج المواد </label>
                                          <flat-pickr
                                            v-model="item.out_date"
                                            class="form-control"
                                            placeholder="حدد تاريخ "
                                          />
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                          {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
   <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                           label="  القسم "
                        label-for="user-department"
                              >
                              
                               

                                <v-select
                                  v-model="item.department_id"
                                  :options="departmentOptions"
                                  label="name"
                                  :reduce="(val) => val.id"
                                  input-id="user-role"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="3"
                            xl="4"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="end_date"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label> تاريخ الصلاحية </label>
                                <flat-pickr
                                  v-model="item.end_date"
                                  class="form-control"
                                  placeholder="حدد تاريخ "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                             <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="">
                      <b-form-group
                        label="   الموظف صاحب المادة  "
                        label-for="user-department"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="user"
                          rules=""
                        >
                          <v-select
                            v-model="item.recived_by_user_id"
                            :options="usersOptions"

                            :reduce="(val) => val.value"
                            input-id="user-role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                   <b-col
                    cols="12"
                    md="4"
                    xl="3"
                    class="invoice-actions"
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" تفاصيل أسباب الارجاع"
                        label-for="payment-method"
                      />
                      <b-form-input
                        v-model="item.destroy_reason"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                  </b-col>
                                    <b-col
                                      cols="4"
                                      md="4"
                                      xl="3"
                                    >
                                      <!-- Payment Method -->
                                      <div class="">
                                        <b-form-group
                                          label=" رقم أمر الشراء"
                                          label-for="payment-method"
                                        />
                                        <b-form-input
                                          v-model="item.po_number"
                                          type="text"
                                          disabled

                                        />
                                        <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                                      </div>

                                    </b-col>
                                    <b-col
                                      cols="12"
                                      md="4"
                                    >
                                      <div class="">
                                         <b-form-group
                                          label="   اسم المستلم الساحب  "
                                          label-for="user-department"
                                        >
                                          {{item.recived_by_user}}
                                        </b-form-group>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <div
                                    class="d-flex flex-column justify-content-between border-left py-50 px-25"
                                  >
                                    <feather-icon
                                      v-if="index != 0"
                                      size="16"
                                      icon="XIcon"
                                      class="cursor-pointer"
                                      @click="removeItem(index)"
                                    />
                                  </div>
                                </div>
                              </b-col>
                            </b-row>

                          </div>
                        </b-col>
                 

                    </b-card-body>
                  </b-card>
                  <hr class="invoice-spacing">

                </b-form>
              </validation-observer>
            </tab-content>
            <tab-content
              title=" اخراج المواد"

              icon="feather icon-file-text"
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <validation-observer
                  #default="{ handleSubmit }"
                  ref="refFormObserver"
                >

                  <b-form
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                  >
                    <b-card
                      no-body
                      class="invoice-preview-card"
                    >
                      <!-- Header -->
                      <b-card-body>
                        <b-row>
                        

                          <!-- <b-col
                      cols="12"
                      lg="3"
                    >
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >

                          <b-form-group
                            label="user_material_order_id"
                            label-for="payment-method"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              v-model="Form.user_material_order_id"
                              t
                              class="mb-2"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </b-col> -->
                          <!-- <b-col
                    cols="12"
                    md="4"
                  >
                    <div class="">
                      <b-form-group
                        label="القسم"
                        label-for="user-department"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="department"
                          rules="required"
                        >
                          <v-select
                            v-model="Form.department_id"
                            :options="departmentOptions"
                            label="name"
                            :reduce="(val) => val.id"
                            input-id="user-role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col> -->
                          <b-col
                            cols="12"
                            md="4"
                          >
                            <div class="">
                              <b-form-group
                                label="الموظف الذي قام بالطلب"
                                label-for="user-department"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="user"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="Form.request_by_user"
                                    :options="usersOptions"

                                    :reduce="(val) => val.value"
                                    input-id="user-role"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </div>
                          </b-col>

                          <b-col
                            cols="12"
                            md="4"
                            xl="3"
                            class="invoice-actions"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label="Cost Center"
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="Form.cost_center"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            xl="3"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label=" مكان الاستلام "
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="Form.receive_place"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>

                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            xl="3"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label="target"
                                label-for="target"
                              />
                              <b-form-input
                                v-model="Form.target"
                                type="number"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            xl="3"
                            class="invoice-actions"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label=" PPa Number"
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="Form.ppa_number"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            xl="3"
                            class="invoice-actions"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label=" ملاحظة"
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="Form.notes"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>
                          </b-col>
                        </b-row>

                      </b-card-body>

                      <!-- Spacer -->

                      <!-- Items Section -->

                      <!-- Note -->
                    </b-card>
                  </b-form>
                </validation-observer>
                <b-form
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <b-card>
                    <b-row>
                      <!-- <b-col
                        cols="12"
                        lg="3"
                      >
                        <div class="mt-2">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-group
                              label="    نوع الوجهة"
                              label-for="payment-method"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                v-model="partTypeTo"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="partTypeOption"
                                :reduce="(val) => val.value"
                                class="mb-2 item-selector-title"
                                @input="getPartyTypeTo(partTypeTo)"
                              />
                            </b-form-group>
                            <b-form-invalid-feedback
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        <div class="mt-2">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-group
                              label="  الوجهة "
                              label-for="payment-method"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                v-model="Form.to_party_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="partOptionTo"
                                label="name"
                                :clearable="false"
                                :reduce="(val) => val.id"
                                class="mb-2 item-selector-title"
                              />
                            </b-form-group>
                            <b-form-invalid-feedback
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </div>
                      </b-col>
                     -->
            
                    </b-row>
                    <b-card-body>

                      <b-row
                        v-for="(item, index) in ItemChecked"
                        :key="index"
                        ref="row"
                        class="pb-2"
                      >
                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                          <!-- Form Input Fields OR content inside bordered area  -->
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div class=" rounded">
                            <b-row class="">
                              <b-col
                                cols="1"
                                lg="1"
                              >
                                <b-form-checkbox
                                  v-model="item.transaction"
                                  class="mr-0 mt-50"
                                  @change="getItem(item)"
                                />
                              </b-col>
                              <!-- Single Item Form Headers -->
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                >

                                  <b-form-group
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <label>المادة</label>
                                    <v-select
                                      v-model="item.item"
                                      :dir="
                                        $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                      "
                                      :options="ItemData"
                                      label="name"
                                      disabled
                                      :clearable="false"
                                      :reduce="(val) => val.id"
                                      class="mb-2 item-selector-title"
                                      placeholder="المادة "
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="3"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :rules="`required|maxValue:${item.remaining_quantity}`"
                                >
                                  <b-form-group
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <label>الكمية</label>
                                    {{ item.remaining_quantity }}
                                    <b-form-input
                                      v-model="item.quantity"
                                      type="number"
                                      :max="item.remaining_quantity"
                                      class="mb-2"

                                      placeholder="الكمية "
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                                 <b-col
                            md="3"
                            xl="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="employe"
                                :state="errors.length > 0 ? false : null"
                              >

                                <label> المورد</label>
                                <v-select
                                  v-model="item.vendor_id"
                                  label="name"
                                  :options="optionVendor"
                                  disabled

                                  :reduce="(val) => val.id"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          
                               <b-col
                    cols="4"
                    md="4"
                    xl="3"
                  
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" رقم أمر الشراء"
                        label-for="payment-method"
                      />
                      <b-form-input
                      disabled

                        v-model="item.po_number"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>

                  </b-col>
                    <b-col
                    cols="4"
                    md="4"
                    xl="3"
                    
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" رقم الفاتورة "
                        label-for="payment-method"
                      />
                      <b-form-input
                      disabled

                        v-model="item.pill_number"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                    </b-col>
                     <b-col
                                cols="4"
                                md="4"
                                xl="3"
                              >
                                <!-- Payment Method -->
                                <div class="">
                                  <b-form-group
                                    label="  destroy reason "
                                    label-for="payment-method"
                                  />
                                  <b-form-input
                                    v-model="item.destroy_reason"

                                    
                                    type="text"
                                  />
                                  <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                                </div>
                              </b-col>

                               <b-col
                                cols="4"
                                md="4"
                                xl="3"
                              >
                                <!-- Payment Method -->
                                <div class="">
                                  <b-form-group
                                    label=" destroy number  "
                                    label-for="payment-method"
                                  />
                                  <b-form-input
                                    v-model="item.destroy_number"

                                    
                                    type="text"
                                  />
                                  <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                                </div>
                               </b-col>
                               <b-col
                                cols="4"
                                md="4"
                                xl="3"
                              >
                                <!-- Payment Method -->
                                <div class="">
                                  <b-form-group
                                    label=" ملاحظة  "
                                    label-for="payment-method"
                                  />
                                  <b-form-input
                                    v-model="item.notes"

                                    
                                    type="text"
                                  />
                                  <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                                </div>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                >
                                  <b-form-group
                                    label-for="notes"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <label>الواحدة</label>
                                    <v-select
                                      v-model="item.unit"
                                      :dir="
                                        $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                      "
                                      disabled
                                      :options="ubitsOption"
                                      label="en_name"
                                      :clearable="false"
                                      :reduce="(val) => val.id"
                                      class="mb-2 item-selector-title"
                                      placeholder="الواحدة "
                                    />

                                  </b-form-group>
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>

                          
                            </b-row>

                          </div>
                        </b-col>
                            <b-col
                                v-if="ItemChecked.length > 0"
                                class="border-Primary mb-10"
                                md="12"
                                xl="12"
                              >
                                <b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="outline-primary"
                                  type="submit"
                                >
                                  <feather-icon
                                    icon="CheckIcon"
                                    class="mr-50 mb-10"
                                  />
                                  <span class="align-middle"> اخراج</span>
                                </b-button>

                              </b-col>
                      </b-row>

                    </b-card-body>

                  </b-card>
                  <hr class="invoice-spacing">

                </b-form>
              </validation-observer>
            </tab-content>

          </form-wizard>
          <div class="d-flex">
            <div>
              <b-button
                v-if="startIndex > 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="shadow"
                @click="lastTab"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  class="mr-50"
                />
                <span class="align-middle"> الصفحة السابقة</span>
              </b-button>
            </div>
            <div class="ml-auto">
              <b-button
                v-if="!isLastStep"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="shadow"
                @click="nextTab"
              >
                <span>الصفحة التالية </span>
                <feather-icon icon="ChevronLeftIcon" />
              </b-button>
            </div>
          </div>
        </b-card>

        <!-- Invoice Description: Total -->

        <!-- Spacer -->

      </b-col>

      <!-- Right Col: Card -->
    </b-row>
    <b-row />

    <!-- <invoice-sidebar-send-invoice />
            <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref, onUnmounted,computed ,watch,reactive,toRefs}  from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,

  BForm,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,
  BFormCheckbox,

  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import financeStoreModule from '../financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
      BFormCheckbox,
    BCol,
    required,
    ValidationProvider,
    ValidationObserver,
    financeStoreModule,
    BFormInvalidFeedback,
    BCard,
    BCardBody,
    BButton,

    BForm,
    BFormGroup,
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    vSelect,
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      file: '',
      firstTab: true,
      isLastStep: false,
      body: '',
      vendor_id: '',
      optionVendor: [],
      startIndex: 0,
    }
  },

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
    this.optionVendor = []
    this.$http.get('/api/v1/vendor').then(res => {
      // console.log('itemres',res);
      this.optionVendor = res.data.data

      // console.log(this.optionVendor);
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    lastTab() {
      this.$refs.formWiz.prevTab()
      this.startIndex--
      this.isLastStep=false
    },
    nextTab() {
      this.$refs.formWiz.nextTab()
      this.startIndex++
      this.isLastStep=true
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup() {
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const Form = ref({
      type_id: 5,
      from_party_id: null,

      user_material_order_id: 1,
      request_by_user: null,
      logistic_officer_signature: null,
      project_manager_signature: null,
      receive_date: '',
      receive_place: '',
      target: null,
      cost_center: null,
      department_id: '',
      ppa_number: null,
      notes: '',
      details: [
   
      ],
    })
    const invoiceData = ref(null)
    const accountList = ref([])
    const SubAccountList = ref([])

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const itemsOptions = ref([
      {
        item_id: '',
        quantity: null,

        unit_id: '',

      },

    ])
    const addNewItemInItemForm = () => {
      Form.value.details.push(
        {
          item_id: '',
          quantity: null,

          unit_id: '',

        },
      )
    }
    const removeItem = index => {
      Form.value.details.splice(index, 1)
    }
    const ItemData = ref([])
    const optionCurrency = ref([])
    store.dispatch('app-finance/GetCurrency').then(response => {
      // console.log('ubitsOption,', optionCurrency)
      optionCurrency.value = response
    })
    const ubitsOption = ref([])
    store.dispatch('app-finance/GetUnits').then(response => {
      // console.log('ubitsOption,', ubitsOption)
      ubitsOption.value = response
    })
    store.dispatch('app-finance/GetItem').then(response => {
      // console.log(response)
      ItemData.value = response
    })
    const warehouseOption = ref([])
    const warehouseChildOption = ref([])
    store.dispatch('app-finance/GetWareHouse').then(response => {
      response.forEach(el => {
        warehouseOption.value.push({
          id: el.id,
          name: el.name,
        })
      })
    })
    const ItemChecked = ref([])
      const thereItem=ref(false)
     const state = reactive({
       originalAllItem: [
        
      ],
      allItem: [
        
      ],
    });

    const { allItem } = toRefs(state);
    const getChildWarehouse = id => {
          warehouseChildOption.value=[]
      store.dispatch('app-finance/getChildTypeWarehouse', id).then(response => {
        response.data.forEach(el => {
          warehouseChildOption.value.push({
            id: el.id,
            name: el.name,
          })
        })
      })
    }
      const searchQuery = ref('');
        watch(searchQuery, () => {
   
  if (searchQuery.value !== '') {
   state.allItem= state.allItem.filter(item =>
     
        item.item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
          
   )
      }
      else{
 state.allItem= state.originalAllItem;
        } 
     
    });
    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }
    const donorOption = ref([])
    const billerOption = ref([])
    store.dispatch('app-finance/GetDonor').then(response => {
      // console.log(response)
      donorOption.value = response
    })
    store.dispatch('app-finance/GetBiller').then(response => {
      // console.log(response)
      billerOption.value = response
    })
    const outComeOption = ref([])
    const outPutOption = ref([])
    const partTypeTo = ref('')
    store.dispatch('app-finance/GetOutCome').then(response => {
      // console.log(response)
      outComeOption.value = response
    })
    store.dispatch('app-finance/GetoutPut').then(response => {
      // console.log(response)
      outPutOption.value = response
    })
    store.dispatch('app-finance/GetAccount').then(response => {
      // console.log(response)
      accountList.value = response
    })
    const partOptionTo = ref([])
    const getPartyTypeTo = id => {
      partOptionTo.value = []
      store.dispatch('app-finance/getPartyType', { id }).then(response => {
        // console.log('ubitsOption,', response)
        if (id == 1) {
          response.data.forEach(el => {
            if (el.details.first_name) {
              partOptionTo.value.push({
                id: el.id,
                name: el.details.first_name,
              })
            }
          })
        } else {
          response.data.forEach(el => {
            if (el.details.name) {
              partOptionTo.value.push({
                id: el.id,
                name: el.details.name,
              })
            }
          })
        }
      })
    }
      const getItem = item => {
      if (!ItemChecked.value.includes(item)) {
        ItemChecked.value.push(item)
        // ItemChecked.value.push({
        //   in_transaction_id:item.id,
        //   "id": item.id,

        //   "quantity":item.quantity,
        //   "item_id": item.item.id,
        //   remaining_quantity:item.remaining_quantity,
        //   "created_at": item.updated_at,
        //   "updated_at": item.updated_at,
        //   "notes": item.notes,
        //   "unit_id": item.unit.id,

        // })
      } else {
        ItemChecked.value = ItemChecked.value.filter(e => e.id !== item.id)
      }
   //   console.log('ItemChecked.value', ItemChecked.value)
    }
    const getSubAccount = id => {
      SubAccountList.value = []
      store.dispatch('app-finance/getSubAccount', { id }).then(response => {
        // console.log('ubitsOption,', response)

        response.forEach(el => {
          SubAccountList.value.push({
            id: el.id,
            name: el.name,
          })
        })
      })
    }
    const departmentOptions = ref([])
    store.dispatch('app-finance/GetDepartment').then(response => {
     // console.log(response)
      departmentOptions.value = response
    })
    const partOption = ref([])

    const getPartyType = id => {
      partOption.value = []
      store.dispatch('app-finance/getPartyType', { id }).then(response => {
        // console.log('ubitsOption,', response)
        if (id == 1) {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.first_name,
            })
          })
        } else {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.name,
            })
          })
        }
      })
    }

    const warehouseTRType = [
      {
        value: 5,
        label: 'اخراج',
      },
      {
        value: 2,
        label: 'نقل',
      },
      {
        value: 3,
        label: 'مرتجع',
      },
      {
        value: 4,
        label: 'تلف',
      },
      {
        value: 5,
        label: 'توزيع',
      },
    ]

    const condition = [
      {
        value: 1,
        label: 'جيد',
      },
      {
        value: 0,
        label: 'ممتاز',
      },
      {
        value: 2,
        label: 'وسط',
      },
      {
        value: 3,
        label: 'ضعيف',
      },

    ]
    const partType = ref('')
    const usersOptions = ref([])
    store.dispatch('app-finance/GetUsers').then(response => {
      // console.log(response)
      usersOptions.value = []

      response.forEach(el => {
        usersOptions.value.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
      })
    })
    const partTypeOption = [
      {
        value: 1,
        label: 'مستخدم',
      },
      {
        value: 2,
        label: 'مستودع',
      },
      {
        value: 3,
        label: 'مورد',
      },
    ]
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const onSubmit = () => {
      // console.log(Form)
 ItemChecked.value.forEach(el => {
        Form.value.details.push({
          in_transaction_id: el.id,
          id: el.id,

          quantity: el.quantity,
          item_id: el.item.id,
          remaining_quantity: el.remaining_quantity,
          created_at: el.updated_at,
          updated_at: el.updated_at,
          notes: el.notes,
          recived_by_user_id:el.recived_by_user_id,
          unit_id: el.unit.id,
          out_date:el.out_date,
           destroy_reason: el.destroy_reason,
          destroy_number: el.destroy_number,
           notes: el.notes,

        })
      })
      store.dispatch('app-finance/createTransaction', Form.value).then(response => {
        ItemChecked.value = []
        Form.value.from_party_id = '',
        Form.value.to_party_id = '',
        Form.value.notes = '',
        Form.value.details = []
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت أضافة الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const showDetails = () => {
      const warehouse = Form.value.main_warehouse_id
      const department = Form.value.department_id

      store.dispatch('app-finance/getReleaseOrder', { warehouse, department }).then(response => {
 
        state.allItem = response.data.data
        if( response.data.data.length > 0){
          thereItem.value=true
        }
         state.originalAllItem = response.data.data
        if (response.data.data.length == 0) {
          Vue.swal({
            title: '',
            text: '   لا يوجد مواد ',
            icon: 'info',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    }
    return {
      usersOptions,
      showDetails,
      getItem,
      warehouseOption,
      allItem,
      ItemChecked,
      getChildWarehouse,
      warehouseChildOption,
      condition,
      departmentOptions,
      Form,
      getPartyTypeTo,
      partOptionTo,
      partTypeTo,
      refFormObserver,
      getValidationState,
      onSubmit,
      resetForm,
      addNewItemInItemForm,
      removeItem,
      ItemData,
      optionCurrency,
      ubitsOption,
      accountList,
      getSubAccount,
      SubAccountList,
      billerOption,
      donorOption,
      outComeOption,
      outPutOption,
      getPartyType,
      partOption,
      partType,
      partTypeOption,
      invoiceData,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      warehouseTRType,
       searchQuery,
      thereItem
    }
  },
}
</script>

  <style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    @import "@core/scss/vue/libs/vue-wizard.scss";
    @import "@core/scss/vue/libs/vue-select.scss";
    </style>
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";

  .form-item-section {
    background-color: $product-details-bg;
  }
  button.btn.btn-outline-primary {
      margin: 1.375rem !important;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
  button.btn.me-20.btn-outline-danger {
    margin-right: 17px;
  }
  </style>
